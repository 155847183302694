import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { catchError, finalize, tap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iif, Observable, of } from 'rxjs';

import { ApplicationService } from '@state/application';
import { Entity } from '@state/entities';
import { handleResolverError$ } from '@helpers/error';
import { ID } from '@state/base';
import { MembershipEntityService, MembershipEntityRepository } from '@state/membership-entity';
import { MembershipsService } from '@state/memberships';
import { USER_OWN_ID } from '@global';

@Injectable({
  providedIn: 'root'
})
export class MembershipEntityResolver {
  constructor(
    private applicationService: ApplicationService,
    private membershipEntityService: MembershipEntityService,
    private membershipEntityRepository: MembershipEntityRepository,
    private membershipsService: MembershipsService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Entity> {
    this.applicationService.pushLoading('membership-entity');

    let activeMembershipEntity = this.membershipEntityRepository.getMembershipEntity();
    let membershipEntityId: ID = route.params.membership_entity_id;
    let providerEntityId: ID = route.params.provider_entity_id;

    // Reset membership entity store when not scoped under a membership-entity route
    // ie: /e/:membership_entity_id
    if (!membershipEntityId) {
      this.membershipEntityRepository.reset();
      membershipEntityId = USER_OWN_ID;
    }

    // Skip getAndStoreMembershipEntity$ if the membership_entity_id belongs to the active membership entity
    if (membershipEntityId === activeMembershipEntity?.id) return;

    return iif(
      () => membershipEntityId === USER_OWN_ID,
      of(undefined),
      this.membershipEntityService.getAndStoreMembershipEntity$(membershipEntityId, providerEntityId)
    ).pipe(
      tap({ next: () => this.membershipsService.get(membershipEntityId, providerEntityId) }),
      catchError((error: HttpErrorResponse) => handleResolverError$(error, this.router)),
      finalize(() => this.applicationService.popLoading('membership-entity'))
    );
  }
}
