import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ID } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';

import { Membership, OrganizationMembership } from './membership.model';
import { MembershipsStore } from './memberships.store';

@Injectable({ providedIn: 'root' })
export class MembershipsService {
  constructor(private http: HttpClient, private membershipsStore: MembershipsStore, private router: Router) {}

  get entityIdInRoute(): string {
    let match = this.router.url.match(/(\/e\/)([0-9a-f\-)]+)/);
    return match && match[2];
  }

  get providerEntityIdInRoute(): string {
    let match = this.router.url.match(/(\/p\/)([0-9a-f\-)]+)/);
    return match && match[2];
  }

  get(id: ID, provider_id?: ID): void {
    this.membershipsStore.setLoading(true);
    let options = {};

    if (provider_id) {
      let headers = new HttpHeaders({ 'Provider-Id': provider_id as string });
      options = { headers };
    }

    this.http.get(`${environment.apiUrl}/v1/user/memberships/${id}`, options).subscribe({
      next: (membership: Membership) => {
        this.membershipsStore.update({ loading: false, membership });
      }
    });
  }

  getAll(): void {
    this.http.get(`${environment.apiUrl}/v1/user/memberships`).subscribe({
      next: (memberships: Membership[]) => {
        this.membershipsStore.set(memberships);
      }
    });
  }

  getAndStoreOrganizationMemberships$(): Observable<OrganizationMembership[]> {
    return this.http.get(`${environment.apiUrl}/v1/user/organization_memberships`).pipe(
      tap({
        next: (organizationMemberships: OrganizationMembership[]) => {
          this.membershipsStore.update({ organizationMemberships });
        }
      })
    );
  }

  getAndSetAll$(): Observable<object> {
    return this.http.get(`${environment.apiUrl}/v1/user/memberships`).pipe(
      tap({
        next: (memberships: Membership[]) => {
          this.membershipsStore.set(memberships);
        }
      })
    );
  }

  setActive(id: ID): void {
    this.membershipsStore.setActive(id);
  }

  updateActive(membership: Membership): void {
    this.membershipsStore.updateActive(membership);
  }
}
