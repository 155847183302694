import { ID } from '@datorama/akita';

import { AssignableType, Form, FormDisplayType, SigningActorRoleType } from '@state/form';
import { BaseModel } from '@state/base';
import { CurrentActor } from '@state/task';

export enum ActionableFormJoinState {
  New = 'new'
}

export enum SignatureRequestActorInvitationState {
  Accepted = 'accepted',
  Pending = 'pending',
  Signed = 'signed'
}

export enum SignatureRequestState {
  Disabled = 'disabled',
  Executed = 'executed',
  Pending = 'pending'
}

// This type is slightly different than the ACtionableFormJoin found in the investor store
// because the payloads are actually different :-/
export interface ActionableFormJoin extends BaseModel {
  assignable_id: ID;
  assignable_type: AssignableType;
  document_id?: ID;
  done_actors: SigningActorRoleType[];
  executed_document?: Record<string, unknown>;
  form: ActionableFormJoinForm;
  form_id: ID;
  state: ActionableFormJoinState;
}

export interface ActionableFormJoinForm extends BaseModel {
  display_type: FormDisplayType;
  title: string;
}

export interface Actor {
  email: string;
  full_name: string;
  id: ID;
  role: SigningActorRoleType;
  user_id: ID;
}

export interface FormTask {
  actor: CurrentActor;
  defaults: Record<string, unknown>;
  form: Form;
}

export interface NextActor {
  acting_user_id?: ID;
  active: boolean;
  archived_at?: string;
  completed_at?: string;
  created_at: string;
  entity_id: ID;
  entity_role?: string;
  id: ID;
  order: number;
  role: SigningActorRoleType;
  signature_request_id: ID;
  task_id?: ID;
  updated_at: string;
  user_id: ID;
}

export interface Organization extends BaseModel {
  allow_cap_call_events: boolean;
  allow_data_room: boolean;
  entity_id: ID;
  name: string;
  primary_color: string;
  saas_enabled: boolean;
  service_agreement_amended_on?: string;
  service_agreement_signed_on?: string;
  service_enabled: boolean;
  url_slug?: string;
}

export interface OrganizationDetails {
  organization: Organization;
  organization_logo?: string;
  vehicle_display_name?: string;
}

export interface SignatureRequestPeek {
  actor_invitation_state: SignatureRequestActorInvitationState;
  archived: boolean;
  id: ID;
  state: SignatureRequestState;
  state_humanized: string;
  user_verified: boolean;
}

export interface SignatureRequest {
  actionable_forms_joins: ActionableFormJoin[];
  actor: Actor;
  actor_invitation_state: SignatureRequestActorInvitationState;
  actor_role: SigningActorRoleType;
  archived: boolean;
  counter_signed: boolean;
  documents: any[];
  executed_documents: any[];
  forms: SignatureRequestForm[];
  id: ID;
  investment_id: ID;
  investment_invitation_token: string;
  invitation_email: string;
  invitation_name: string;
  invitation_token: string;
  message?: string;
  next_actor: NextActor;
  next_task_id?: ID;
  next_task_signature_token?: string;
  organization_details: OrganizationDetails;
  state: SignatureRequestState;
  state_humanized: string;
  task_id: ID;
  task_title: string;
  task_type: string;
  user_verified: boolean;
  vehicle_id?: ID;
  workflow_id: ID;
}

// This varies significantly from the standard form – we should
// talk about unifying these types at some point on the BE
export interface SignatureRequestForm extends BaseModel {
  active: boolean;
  assignableId: ID;
  assignable_to: AssignableType;
  description: string;
  display_type: FormDisplayType;
  document_id: ID;
  field_roles: SigningActorRoleType[];
  global: boolean;
  internal: boolean;
  organization_id: ID;
  preview: string;
  printable: boolean;
  rtd: boolean;
  title: string;
  vehicle_id: ID;
}
