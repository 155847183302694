import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';

import { Control, FormLayoutRepository } from '@state/form-layout';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'fl-blank-layout',
  styleUrls: ['./blank-layout.component.scss'],
  templateUrl: './blank-layout.component.html'
})
export class BlankLayoutComponent implements OnInit {
  title$: Observable<string>;
  controls$: Observable<Control[]>;

  constructor(private formLayoutRepository: FormLayoutRepository) {}

  ngOnInit() {
    this.controls$ = this.formLayoutRepository.controls$;
    this.title$ = this.formLayoutRepository.title$;
  }
}
