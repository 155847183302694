/*
  App Routing
  Defines and handles public routes that don't require authentication to access
*/

// Angular Dependencies
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@guard/auth-guard/auth.guard';
import { EntityRedirectGuard } from '@guard/entity-redirect-guard';
import { FlowDotComRedirectGuard } from '@guard/flow-dot-com-redirect-guard';
import { FundRedirectGuard } from '@guard/fund-redirect-guard';
import { InvestmentResolver } from '@resolver/investment/investment.resolver';
import { LoginRedirectGuard } from '@guard/login-redirect-guard/login-redirect.guard';
import { MembershipEntityResolver } from '@resolver/membership-entity/membership-entity.resolver';
import { PortfolioReportResolver } from '@resolver/portfolio-report';
import { PublicInfoResolver } from '@resolver/public-info/public-info.resolver';
import { ReportResolver } from '@resolver/report';
import { ReportsResolver } from '@resolver/reports';
import { OrganizationLoginRedirectGuard } from '@guard/organization-login-redirect-guard/organization-login-redirect-guard';
import { TargetResourceGuard } from '@guard/target-destination-resource/target-resource.guard';
import { VisibilityDocumentGuard } from '@guard/visibility-document-guard';

import { AppLayoutComponent, AppLayoutModule } from './layout/app-layout';

// splitting any of these line actually breaks the dialyzer preventing the application from loading

// Instance Members
const routes: Routes = [
  // Static routes
  {
    canActivate: [FlowDotComRedirectGuard],
    loadChildren: () => import('./route/home/home.module').then((m) => m.HomeModule),
    path: ''
  },
  {
    canActivate: [FlowDotComRedirectGuard],
    loadChildren: () => import('./route/home/home.module').then((m) => m.HomeModule),
    path: 'services'
  },
  {
    canActivate: [],
    loadChildren: () => import('./route/svb/svb.module').then((m) => m.SvbModule),
    path: 'svb'
  },
  {
    canActivate: [FlowDotComRedirectGuard],
    loadChildren: () => import('./route/webinar/webinar.module').then((m) => m.WebinarModule),
    path: 'webinar'
  },
  {
    canActivate: [FlowDotComRedirectGuard],
    data: {
      signup: true
    },
    loadChildren: () => import('./route/webinar/webinar.module').then((m) => m.WebinarModule),
    path: 'webinar-sign-up'
  },
  {
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./views/authenticated/authenticated.module').then((m) => m.AuthenticatedModule),
    path: ''
  },
  // --- Contacts Group ---
  {
    canActivate: [AuthGuard],
    data: { segment: 'Contacts' },
    loadChildren: () => import('./route/contacts/contacts.module').then((m) => m.ContactsModule),
    path: 'contacts',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Contacts' },
    loadChildren: () => import('./route/contacts/contacts.module').then((m) => m.ContactsModule),
    path: 'e/:membership_entity_id/contacts',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Contacts' },
    loadChildren: () => import('./route/contacts/contacts.module').then((m) => m.ContactsModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/contacts',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  // --- Data Rooms Group ---
  {
    canActivate: [AuthGuard],
    data: { scope: 'user', segment: 'Data Rooms' },
    loadChildren: () => import('./route/data-rooms/data-rooms.module').then((m) => m.DataRoomsModule),
    path: 'data-rooms',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { scope: 'gp', segment: 'Data Rooms' },
    loadChildren: () => import('./route/data-rooms/data-rooms.module').then((m) => m.DataRoomsModule),
    path: 'e/:membership_entity_id/data-rooms',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Data Room' },
    loadChildren: () => import('./route/data-room/data-room.module').then((m) => m.DataRoomModule),
    path: 'e/:membership_entity_id/data-room/:data_room_id',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  // --- Documents: Top-level
  {
    path: 'documents/tax',
    redirectTo: 'documents'
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Documents' },
    loadChildren: () => import('./route/documents/documents.module').then((m) => m.DocumentsModule),
    path: 'documents',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Documents' },
    loadChildren: () => import('./route/documents/documents.module').then((m) => m.DocumentsModule),
    path: 'e/:membership_entity_id/documents',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Documents' },
    loadChildren: () => import('./route/documents/documents.module').then((m) => m.DocumentsModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/documents',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  // --- Document: Top-level
  {
    canActivate: [AuthGuard],
    data: { segment: 'Document' },
    loadChildren: () => import('./route/document/document.module').then((m) => m.DocumentModule),
    path: 'document',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Document' },
    loadChildren: () => import('./route/document/document.module').then((m) => m.DocumentModule),
    path: 'e/:membership_entity_id/document',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Document' },
    loadChildren: () => import('./route/document/document.module').then((m) => m.DocumentModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/document',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    path: 'documents/:document_id',
    redirectTo: 'document/:document_id'
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Document' },
    loadChildren: () => import('./route/rtd/rtd.module').then((m) => m.RtdModule),
    path: 'rtd/:rtd_id',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Document' },
    loadChildren: () => import('./route/rtd/rtd.module').then((m) => m.RtdModule),
    path: 'e/:membership_entity_id/rtd/:rtd_id',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Document' },
    loadChildren: () => import('./route/rtd/rtd.module').then((m) => m.RtdModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/rtd/:rtd_id',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  // --- Entities Group ---
  {
    canActivate: [AuthGuard],
    data: { segment: 'Entities' },
    loadChildren: () => import('./route/entities/entities.module').then((m) => m.EntitiesModule),
    path: 'entities',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Entities' },
    loadChildren: () => import('./route/entities/entities.module').then((m) => m.EntitiesModule),
    path: 'e/:membership_entity_id/entities',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Entities' },
    loadChildren: () => import('./route/entities/entities.module').then((m) => m.EntitiesModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/entities',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Entity' },
    loadChildren: () => import('./route/entity/entity.module').then((m) => m.EntityModule),
    path: 'entity/:entity_id'
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Entity' },
    loadChildren: () => import('./route/entity/entity.module').then((m) => m.EntityModule),
    path: 'e/:membership_entity_id/entity/:entity_id',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Entity' },
    loadChildren: () => import('./route/entity/entity.module').then((m) => m.EntityModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/entity/:entity_id',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  // --- Signatures Group ---
  {
    canActivate: [AuthGuard],
    data: { segment: 'Signatures' },
    loadChildren: () => import('./route/signatures/signatures.module').then((m) => m.SignaturesModule),
    path: 'signatures',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Signatures' },
    loadChildren: () => import('./route/signatures/signatures.module').then((m) => m.SignaturesModule),
    path: 'e/:membership_entity_id/signatures',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Signatures' },
    loadChildren: () => import('./route/signatures/signatures.module').then((m) => m.SignaturesModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/signatures',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  // --- Data Requests Group ---
  {
    data: { segment: 'Signature' },
    loadChildren: () => import('./route/data-request/data-request.module').then((m) => m.DataRequestModule),
    path: 'data-request/:token',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    data: { segment: 'Signature' },
    loadChildren: () => import('./route/data-request/data-request.module').then((m) => m.DataRequestModule),
    path: 'e/:membership_entity_id/data-request/:token',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    data: { segment: 'Signature' },
    loadChildren: () => import('./route/data-request/data-request.module').then((m) => m.DataRequestModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/data-request/:token',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  // --- Dashboard Group ---
  {
    canActivate: [TargetResourceGuard],
    data: { segment: 'Dashboard' },
    loadChildren: () => import('./route/dashboard/dashboard.module').then((m) => m.DashboardModule),
    path: 'dashboard',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [TargetResourceGuard],
    data: { segment: 'Dashboard' },
    loadChildren: () => import('./route/dashboard/dashboard.module').then((m) => m.DashboardModule),
    path: 'e/:membership_entity_id/dashboard',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [TargetResourceGuard],
    data: { segment: 'Dashboard' },
    loadChildren: () => import('./route/dashboard/dashboard.module').then((m) => m.DashboardModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/dashboard',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  // --- Clients Group ---
  {
    canActivate: [AuthGuard],
    data: { segment: 'Clients' },
    loadChildren: () => import('./route/clients/clients.module').then((m) => m.ClientsModule),
    path: 'e/:membership_entity_id/clients',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  // --- Funds Group ---
  {
    canActivate: [AuthGuard],
    data: { segment: 'Funds' },
    loadChildren: () => import('./route/funds/funds.module').then((m) => m.FundsModule),
    path: 'funds',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Funds' },
    loadChildren: () => import('./route/funds/funds.module').then((m) => m.FundsModule),
    path: 'e/:membership_entity_id/funds',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Funds' },
    loadChildren: () => import('./route/funds/funds.module').then((m) => m.FundsModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/funds',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard, FundRedirectGuard],
    data: { segment: 'Fund' },
    loadChildren: () => import('./route/fund/fund.module').then((m) => m.FundModule),
    path: 'fund/:fund_id'
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Fund' },
    loadChildren: () => import('./route/fund/fund.module').then((m) => m.FundModule),
    path: 'e/:membership_entity_id/fund/:fund_id',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Fund' },
    loadChildren: () => import('./route/fund/fund.module').then((m) => m.FundModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/fund/:fund_id',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  // --- Investments Group ---
  {
    canActivate: [AuthGuard],
    data: { segment: 'Investments' },
    loadChildren: () => import('./route/investments/investments.module').then((m) => m.InvestmentsModule),
    path: 'investments',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Investments' },
    loadChildren: () => import('./route/investments/investments.module').then((m) => m.InvestmentsModule),
    path: 'e/:membership_entity_id/investments',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Investments' },
    loadChildren: () => import('./route/investments/investments.module').then((m) => m.InvestmentsModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/investments',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Investment' },
    loadChildren: () =>
      import('./route/investment-holding/investment-holding.module').then((m) => m.InvestmentHoldingModule),
    path: 'investment/holding/:holding_id',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Investment' },
    loadChildren: () =>
      import('./route/investment-holding/investment-holding.module').then((m) => m.InvestmentHoldingModule),
    path: 'e/:membership_entity_id/investment/holding/:holding_id',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Investment' },

    loadChildren: () =>
      import('./route/investment-holding/investment-holding.module').then((m) => m.InvestmentHoldingModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/investment/holding/:holding_id',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Investment' },
    loadChildren: () =>
      import('./route/investment-portfolio-company/investment-portfolio-company.module').then(
        (m) => m.InvestmentPortfolioCompanyModule
      ),
    path: 'investment/portfolio-company/:entity_id',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Investment' },
    loadChildren: () =>
      import('./route/investment-portfolio-company/investment-portfolio-company.module').then(
        (m) => m.InvestmentPortfolioCompanyModule
      ),
    path: 'e/:membership_entity_id/investment/portfolio-company/:entity_id',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Investment' },
    loadChildren: () =>
      import('./route/investment-portfolio-company/investment-portfolio-company.module').then(
        (m) => m.InvestmentPortfolioCompanyModule
      ),
    path: 'p/:provider_entity_id/e/:membership_entity_id/investment/portfolio-company/:entity_id',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Investment' },
    loadChildren: () => import('./route/investment/investment.module').then((m) => m.InvestmentModule),
    path: 'investment/:investment_id',
    resolve: {
      investment: InvestmentResolver,
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Investment' },
    loadChildren: () => import('./route/investment/investment.module').then((m) => m.InvestmentModule),
    path: 'e/:membership_entity_id/investment/:investment_id',
    resolve: {
      investment: InvestmentResolver,
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Investment' },
    loadChildren: () => import('./route/investment/investment.module').then((m) => m.InvestmentModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/investment/:investment_id',
    resolve: {
      investment: InvestmentResolver,
      membershipEntity: MembershipEntityResolver
    }
  },
  // GP Reports - Index
  {
    canActivate: [AuthGuard],
    loadChildren: () => import('./route/reports/reports.module').then((m) => m.ReportsModule),
    path: 'reports',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    loadChildren: () => import('./route/reports/reports.module').then((m) => m.ReportsModule),
    path: 'e/:membership_entity_id/reports',
    resolve: {
      membershipEntity: MembershipEntityResolver,
      reportData: ReportsResolver
    }
  },
  {
    canActivate: [AuthGuard],
    loadChildren: () => import('./route/reports/reports.module').then((m) => m.ReportsModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/reports',
    resolve: {
      membershipEntity: MembershipEntityResolver,
      reportData: ReportsResolver
    }
  },
  {
    canActivate: [AuthGuard],
    loadChildren: () => import('./route/report/report.module').then((m) => m.ReportModule),
    path: 'e/:membership_entity_id/report',
    resolve: {
      membershipEntity: MembershipEntityResolver,
      report: ReportResolver
    },
    title: 'Report'
  },
  {
    canActivate: [AuthGuard],
    loadChildren: () => import('./route/report/report.module').then((m) => m.ReportModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/report',
    resolve: {
      membershipEntity: MembershipEntityResolver,
      report: ReportResolver
    },
    title: 'Report'
  },
  // --- Invitations Group ---
  {
    data: { segment: 'Invitation' },
    loadChildren: () => import('./route/invitation/invitation.module').then((m) => m.InvitationModule),
    path: 'invitation/:token',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    data: { segment: 'Invitation' },
    loadChildren: () => import('./route/invitation/invitation.module').then((m) => m.InvitationModule),
    path: 'g/:organization_slug/invitation/:token',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    data: { segment: 'Invitation' },
    loadChildren: () => import('./route/invitation/invitation.module').then((m) => m.InvitationModule),
    path: 'e/:membership_entity_id/invitation/:token',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    data: { segment: 'Invitation' },
    loadChildren: () => import('./route/invitation/invitation.module').then((m) => m.InvitationModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/invitation/:token',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  // --- Settings and General Auth Nav ---
  {
    canActivate: [AuthGuard],
    component: AppLayoutComponent,
    data: { segment: 'Settings' },
    loadChildren: () => import('./route/settings/settings.module').then((m) => m.SettingsModule),
    path: 'settings',
    resolve: {
      membershipEntity: MembershipEntityResolver
    },
  },
  {
    canActivate: [AuthGuard],
    data: { embedded: true, segment: 'Settings' },
    loadChildren: () => import('./route/settings/settings.module').then((m) => m.SettingsModule),
    path: 'embedded/settings',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Settings' },
    loadChildren: () => import('./route/settings/settings.module').then((m) => m.SettingsModule),
    path: 'e/:membership_entity_id/settings',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Settings' },
    loadChildren: () => import('./route/settings/settings.module').then((m) => m.SettingsModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/settings',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Switch Profile' },
    loadChildren: () =>
      import('./route/switch-profile/switch-profile.module').then((m) => m.SwitchProfileModule),
    path: 'switch-profile'
  },
  // --- Workflows Group ---
  {
    canActivate: [AuthGuard],
    data: { segment: 'Workflow' },
    loadChildren: () => import('./route/workflow/workflow.module').then((m) => m.WorkflowModule),
    path: 'workflow/:workflow_id',
    resolve: { membershipEntity: MembershipEntityResolver }
  },
  {
    canActivate: [AuthGuard],
    data: { scope: 'organizations', segment: 'Workflows' },
    loadChildren: () => import('./route/workflows/workflows.module').then((m) => m.WorkflowsModule),
    path: 'e/:membership_entity_id/workflows',
    resolve: { membershipEntity: MembershipEntityResolver }
  },
  {
    canActivate: [AuthGuard],
    data: { scope: 'organizations', segment: 'Workflows' },
    loadChildren: () => import('./route/workflows/workflows.module').then((m) => m.WorkflowsModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/workflows',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Workflow' },
    loadChildren: () => import('./route/workflow/workflow.module').then((m) => m.WorkflowModule),
    path: 'e/:membership_entity_id/workflow/:workflow_id',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard],
    data: { segment: 'Workflow' },
    loadChildren: () => import('./route/workflow/workflow.module').then((m) => m.WorkflowModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/workflow/:workflow_id',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  // ------------------------------------------------------------------------------------------
  // --- Auth: Signup, login, etc ---
  {
    data: { forgotPassword: true, segment: 'Forgot Password', showLoginLink: true },
    loadChildren: () => import('./route/forgot-password/forgot-password-route.module').then((m) => m.ForgotPasswordRouteModule),
    path: 'forgot-password'
  },
  {
    data: { forgotPassword: true, segment: 'Forgot Password', showLoginLink: true },
    loadChildren: () => import('./route/g/forgot-password/organization-forgot-password-route.module').then((m) => m.OrganizationForgotPasswordRouteModule),
    path: 'g/:organization_slug/forgot-password',
    resolve: {
      organizationInfo: PublicInfoResolver
    }
  },
  {
    canActivate: [LoginRedirectGuard],
    data: { segment: 'Login' },
    loadChildren: () => import('./route/login/login-route.module').then((m) => m.LoginRouteModule),
    path: 'login'
  },
  {
    canActivate: [OrganizationLoginRedirectGuard],
    data: { segment: 'Login' },
    loadChildren: () => import('./route/g/login/organization-login-route.module').then((m) => m.OrganizationLoginRouteModule),
    path: 'g/:organization_slug/login',
    resolve: {
      organizationInfo: PublicInfoResolver
    }
  },
  {
    data: { segment: 'Reset Password', showLoginLink: true },
    loadChildren: () =>
      import('./route/reset-password/reset-password-route.module').then((m) => m.ResetPasswordRouteModule),
    path: 'reset-password/:token'
  },
  {
    data: { segment: 'Reset Password', showLoginLink: true },
    loadChildren: () =>
      import('./route/g/reset-password/organization-reset-password-route.module').then(
        (m) => m.OrganizationResetPasswordRouteModule
      ),
    path: 'g/:organization_slug/reset-password/:token',
    resolve: {
      organizationInfo: PublicInfoResolver
    }
  },
  {
    data: { segment: 'Sign Up' },
    loadChildren: () => import('./route/signup/signup-route.module').then((m) => m.SignupRouteModule),
    path: 'signup'
  },
  {
    data: { segment: 'Sign Up' },
    loadChildren: () => import('./route/g/signup/organization-signup-route.module').then((m) => m.OrganizationSignupRouteModule),
    path: 'g/:organization_slug/signup',
    resolve: {
      organizationInfo: PublicInfoResolver
    }
  },
  {
    loadChildren: () => import('./route/oauth/oauth.module').then((m) => m.OauthModule),
    path: 'oauth'
  },
  // --- Public Landing Pages ---
  {
    canActivate: [FlowDotComRedirectGuard],
    loadChildren: () =>
      import('./route/maintenance-and-support-agreement/maintenance-and-support-agreement.module').then(
        (m) => m.MaintenanceAndSupportAgreementModule
      ),
    path: 'maintenance-and-support-agreement'
  },
  {
    canActivate: [FlowDotComRedirectGuard],
    data: { segment: 'Team' },
    loadChildren: () => import('./route/team/team.module').then((m) => m.TeamModule),
    path: 'team'
  },
  {
    canActivate: [FlowDotComRedirectGuard],
    loadChildren: () =>
      import('./route/privacy-policy/privacy-policy.module').then((m) => m.PrivacyPolicyModule),
    path: 'privacy'
  },
  {
    canActivate: [FlowDotComRedirectGuard],
    loadChildren: () => import('./route/terms-of-use/terms-of-use.module').then((m) => m.TermsOfUseModule),
    path: 'terms-of-use'
  },
  // --- Old route prefixes ---
  {
    canActivate: [AuthGuard, EntityRedirectGuard],
    loadChildren: () =>
      import('./route/entity-redirect/entity-redirect.module').then((m) => m.EntityRedirectModule),
    path: 'o/:organization_id'
  },
  {
    canActivate: [AuthGuard, EntityRedirectGuard],
    loadChildren: () =>
      import('./route/entity-redirect/entity-redirect.module').then((m) => m.EntityRedirectModule),
    path: 'ia/:investment_account_id'
  },
  {
    canActivate: [AuthGuard, VisibilityDocumentGuard],
    loadChildren: () => import('./route/stub/stub.module').then((m) => m.StubModule),
    path: 'visibility-document/:visibility_document_id',
    resolve: {
      membershipEntity: MembershipEntityResolver
    }
  },
  {
    canActivate: [AuthGuard, VisibilityDocumentGuard],
    loadChildren: () => import('./route/stub/stub.module').then((m) => m.StubModule),
    path: 'e/:membership_entity_id/visibility-document/:visibility_document_id'
  },
  {
    canActivate: [AuthGuard, VisibilityDocumentGuard],
    loadChildren: () => import('./route/stub/stub.module').then((m) => m.StubModule),
    path: 'p/:provider_entity_id/e/:membership_entity_id/visibility-document/:visibility_document_id'
  },
  // Portfolio Report
  {
    loadChildren: () =>
      import('./route/portfolio-report/portfolio-report.module').then((m) => m.PortfolioReportModule),
    path: 'portfolio-report',
    resolve: {
      portfolioReport: PortfolioReportResolver
    }
  },
  // --- 404 ---
  {
    data: { segment: 'Not Found' },
    loadChildren: () => import('./route/four-oh-four/four-oh-four.module').then((m) => m.FourOhFourModule),
    path: '**'
  }
];

/* eslint-enable max-len */

// Module Definition
@NgModule({
  exports: [RouterModule],
  imports: [
    AppLayoutModule,
    RouterModule.forRoot(routes, {
      anchorScrolling: 'enabled',
      paramsInheritanceStrategy: 'always',
      scrollPositionRestoration: 'enabled'
    })
  ]
})

// Class Definition
export class DocumentsAppRoutingModule {}
