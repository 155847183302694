import { Injectable } from '@angular/core';
import { ID, Store, StoreConfig } from '@datorama/akita';
import { BaseModel } from '@state/base';
import { EntityMemberRole } from '@state/entities';

export interface Organization {
  id: ID;
  name: string;
}

// TODO: There is a mismatch between what the user from the cookie and user
// from the requests looks like, however we treat them as one and the same in
// the client, and there are many attributes that may be missing (in particalar,
// from the cookie version of the user which is greatly reduced) – We should either
// Unify what these look like coming from the server, or split them out into seperate
// objects / concerns
export interface User {
  admin: boolean;
  doc_admin: boolean;
  email: string;
  enable_mfa?: boolean;
  exp: number;
  first_name?: string;
  full_name?: string;
  fundmanager: boolean;
  gp?: boolean;
  has_individual_investment_account?: boolean;
  id?: ID;
  initials?: string;
  investment_accounts_count?: number;
  investments_count?: number;
  is_legal_team: boolean;
  jwt_token: string;
  last_name?: string;
  last_organization_id?: string;
  middle_name?: string;
  mobile_phone?: string;
  mobile_verified?: boolean;
  name: string;
  organization?: Organization;
  organization_id?: string;
  organization_member: boolean;
  super_admin?: boolean;
  user_id?: ID;
  vehicle_member: boolean;
}

export interface UserInvestmentAccount extends BaseModel {
  account_name: null;
  accr_affiliated_investors?: unknown[];
  accr_assets_options: string;
  accr_company_type?: string;
  accr_erisa_dol_1: boolean;
  accr_erisa_dol_2: boolean;
  accr_erisa_dol_3: boolean;
  accr_erisa_dol_4: boolean;
  accr_investment_company_act?: boolean;
  accr_investment_company_reps?: unknown;
  accr_investment_rep_1?: unknown;
  accr_investment_rep_2?: unknown;
  accr_investment_rep_3?: unknown;
  accr_investment_rep_4?: unknown;
  accr_investment_rep_5?: unknown;
  accr_investor_rep_non_us_fatca: boolean;
  accr_investor_rep_non_us_w8: boolean;
  accr_investor_rep_us_person: boolean;
  accr_trust_type?: string;
  accr_type_business: boolean;
  accr_type_income: boolean;
  accr_type_networth: boolean;
  accr_written_disclosure: string;
  accreditation_complete: boolean;
  accredited: boolean;
  active_deals: number;
  address?: {
    line_one: string;
    line_two?: string;
    state: string;
    state_upcase: string;
    postal: string;
    city: string;
    addressable_type: string;
    full_address: string;
    locality: string;
    country: string;
    street_address: string;
    state_code?: string;
    state_code_upcase?: string;
  };
  all_documents: unknown[];
  assigned_documents: unknown[];
  associated_deals: unknown[];
  associated_vehicles: unknown[];
  associated_entity: {
    id: ID;
    legal_name: string;
    logo?: string;
    location: string;
  };
  account_number?: string;
  bank_name?: string;
  company_accreditation_complete: boolean;
  complete: boolean;
  contact_email: string;
  contact_name: string;
  contact_title: string;
  date_of_formation?: string;
  display_label: string;
  entity_subtype?: string;
  entity_type: string;
  fax_number: string;
  formation_certificate_document_id?: ID;
  formation_country?: string;
  formation_state?: string;
  has_bank_account: boolean;
  individual_accreditation_complete: boolean;
  investor: {
    id: ID;
    first_name: string;
    last_name: string;
    email: string;
    full_name: string;
    initials: string;
  };
  legal_name: string;
  members: {
    id: ID;
    entity_id: ID;
    role: string;
    title?: string;
    created_at: string;
    created_at_formatted: string;
    entity_member_id: string;
    role_humanized: string;
    invitation?: UserInvestmentAccountInvitation;
    invitation_state?: string;
    user_verified: boolean;
    first_name: string;
    middle_name?: string;
    last_name: string;
    email: string;
    name: string;
    full_name: string;
    entity_member_roles: EntityMemberRole[]
  }[];
  operating_agreement_document_id?: ID;
  phone_number?: number;
  proof_of_id_document: unknown[];
  qualified_client: boolean;
  qualified_purchaser_2: boolean;
  qualified_purchaser: boolean;
  routing_number?: string;
  tax_document: unknown[];
  tax_id_last_four?: string;
  tax_id_type: string;
  tax_id?: string;
  total_contributed_formatted: string;
  total_contributed: number;
  trust_accreditation_complete: boolean;
  trust_agreement_document_id?: ID;
  trust_subtype?: string;
  trust_type?: string;
  type_humanized: string;
  type: string;
  wire_instructions?: string;
}

export interface UserInvestmentAccountInvitation {
  api_message_id?: ID;
  api_source?: string;
  created_at: string;
  declined_at?: string;
  id: string;
  inviteable_id: string;
  inviteable_type: string;
  message: string;
  name: string;
  redirect_url: string;
  sender_cc?: string;
  sender_id: string;
  sent_at?: string;
  sent_to: string;
  state: string;
  token: string;
  updated_at: string;
  user_id: ID;
}

export interface UserState {
  hasFetchedUser: boolean;
  user?: User;
}

function createInitialState(): UserState {
  return { hasFetchedUser: false, user: undefined };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'session', resettable: true })
export class UserStore extends Store<UserState> {
  constructor() {
    super(createInitialState());
  }
}
